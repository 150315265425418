@import url("https://use.typekit.net/lwe6mwe.css");
/* new fonts */

@font-face {
  font-family: "acumin-pro";
  src: url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}
@font-face {
  font-family: "acumin-pro";
  src: url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "PP Formula Condensed";
  src: url("./fonts/PPFormula-CondensedBold.woff2") format("woff2"),
    url("./fonts/PPFormula-CondensedBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  scroll-behavior: initial;
}

.cover-full-dom {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  /* list-style: none; */
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

ol,
ul {
  margin: 0;
  padding: 0;
  padding-left: 0 !important;
}
img {
  display: block;
}
body {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  overflow-x: hidden;
}

.text-darkGreen {
  color: #3bc86b;
}
.welcome-heading-section {
  display: flex;
  align-items: center;
}
.intandem-logo {
  width: 10%;
  height: 100%;
}
@media screen and (max-width: 500px) {
  .welcome-heading-section {
    display: block;
    align-items: center;
    text-align: left;
  }
  .intandem-logo {
    width: 40%;
    height: 100%;
  }
}
a {
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  text-decoration: none !important;
}

.homepage-hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  align-items: center;
}

.section-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1300px;
}

/* gradients */
.vipPage1-grad {
  width: 30%;
  height: 38vh;
  position: absolute;
  top: 50px;
  left: 18%;
  z-index: -1;

  background: radial-gradient(
    circle at center,
    #d3d574 0,
    #eff090 10%,
    white 100%
  );

  filter: blur(60px);
}

.grad {
  width: 30%;
  height: 38vh;
  position: absolute;
  top: 100px;
  left: 18%;
  z-index: -1;

  background: radial-gradient(
    circle at center,
    #e5e5e5 0,
    #e5e5e5 10%,
    white 100%
  );

  filter: blur(60px);
  opacity: 0.7;
}

.grad1 {
  width: 30%;
  height: 38vh;
  position: absolute;
  top: 400px;
  left: 12%;
  z-index: -1;
  background: radial-gradient(
    circle at center,
    rgba(234, 132, 150, 0.33),
    rgba(223, 128, 142, 0.51),
    white
  );

  filter: blur(60px);

  opacity: 0.5;
}

/* **** Signup **** */
.signup-wrp {
  padding: 100px 0;
  width: 100%;
  position: relative;
}
.signup-wrp h1 {
  font-size: 50px;
  font-weight: normal;
  color: #000;
  text-align: center;

  margin: 0 0 25px;
}
.signup-wrp h2 {
  font-size: 38px;
  font-weight: 800;
  color: #000;
  text-align: center;
  line-height: 52px;
  margin: 0 0 22px;
  font-family: "acumin-pro";
}
.signup-wrp h2 span {
  color: #3bc86b;
  font-family: "acumin-pro";
}
.code-frm {
  width: 830px;
  margin: 0 auto;
  display: table;
}
.code-frm h3 {
  font-size: 38px;
  font-weight: 900;
  color: #ff8282;
  text-align: center;

  font-style: italic;
  letter-spacing: 1px;
  margin: 0 0 20px;
  font-family: "acumin-pro";
}
.formBox {
  position: relative;
  margin: 0 0 22px;
}
.formBox span {
  display: none;
}
.formBox.error-msg span {
  display: block;
  font-size: 18px;
  font-weight: normal;
  color: #d91538;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}
.formBox .form-control {
  padding: 10px 25px 10px;
  border: 5px solid #000000;
  height: auto;
  box-shadow: none;
  border-radius: 0;
  font-size: 18px;
  font-weight: normal;
  color: #000;
}
.formBox .form-control:focus {
  box-shadow: none;
  border: 5px solid #000000;
}
.code-frm button,
.code-frm .btn-main {
  font-size: 18px;
  font-weight: normal;
  color: #232740;
  padding: 8px 20px 6px;
  border-radius: 30px;

  text-align: center;
  width: 300px;
  margin: 0 auto;
  display: table;
  border: none;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  text-decoration: none;
}
.code-frm .btn1 {
  background: radial-gradient(
    51.23% 1206.62% at 46.37% 42.2%,
    #febf10 0%,
    rgba(254, 91, 190, 0.82) 79.69%
  );
}
.code-frm .btn2 {
  background: linear-gradient(0deg, #77f9a3, #77f9a3);
}

.rightbarfix {
  position: absolute;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform-origin: right;
  right: 10%;
  top: 80%;
}
.rightbarfix ul {
  display: flex;
  align-items: center;
}
.rightbarfix ul li {
  margin-right: 40px;
  position: relative;
}
.rightbarfix ul li:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 2px;
  background-color: #333333;
  right: -25px;
  top: 8px;
}
.rightbarfix ul li:last-child {
  margin: 0;
}
.rightbarfix ul li:last-child:after {
  display: none;
}
.rightbarfix ul li a {
  font-size: 14px;
  font-weight: normal;
  color: #333333;
  display: block;
  letter-spacing: 1px;
}
.rightbarfix ul li a:hover {
  color: #ff8282;
}
img.signup-circle {
  position: absolute;
  top: 5%;
  left: 15%;
  z-index: -1;
  width: 35%;
  display: none;
}

.invite-code {
  padding: 30px 0;
  /* background-color: #ff8282; */
  background-color: #ffdf36;
  width: 100%;
}
.invite-code h2 {
  font-size: 42px;
  font-weight: 800;
  color: #000;
  text-align: center;
  margin: 0 0 15px;
  font-family: "acumin-pro";
}
.invite-code .code-frm button:hover {
  background: #232740;
  color: #fff;
}
/* **** End Signup **** */

/* ***** Wizard ****** */
.main-wizard-wrp {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.form-wizard {
}
.form-wizard .form-wizard-next-btn,
.form-wizard .form-wizard-previous-btn,
.form-wizard .form-wizard-submit {
  font-size: 18px;
  font-weight: normal;
  color: #232740;
  padding: 12px 20px;
  border-radius: 30px;
  background: linear-gradient(0deg, #77f9a3, #77f9a3);
  text-align: center;
  width: 300px;
  margin: 0 auto;
  display: table;
  border: none;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  text-decoration: none;
}
.form-wizard .form-wizard-next-btn:hover,
.form-wizard .form-wizard-next-btn:focus,
.form-wizard .form-wizard-submit:hover,
.form-wizard .form-wizard-submit:focus {
  background: #232740;
  color: #fff;
  text-decoration: none;
}
.form-wizard .wizard-fieldset {
  display: none;
}
.form-wizard .wizard-fieldset.show {
  display: block;
}
.form-wizard .wizard-form-error {
  display: none;
  background-color: #d70b0b;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.form-wizard .form-wizard-previous-btn {
  background-color: #fb1647;
}
.form-wizard .form-wizard-steps {
  margin: 30px 0;
}
.inner-wizard-box {
  padding: 20px 0;
}
.inner-wizard-box h2 {
  font-size: 42px;
  font-weight: bold;
  color: #000;
  line-height: 56px;

  font-family: "acumin-pro";
}
.inner-wizard-box h2 span {
  color: #fe79ca;
}
.inner-content-dt {
  margin: 0 auto;
  max-width: 750px;
}
.inner-content-dt p {
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.4;
  font-family: "acumin-pro";
}
.inner-content-dt-span {
  color: #3bc86b;
  font-weight: 700;
  font-family: "acumin-pro";
}
.step-wrapper {
  padding: 60px 0;
  position: relative;
}

.top-action-box .form-wizard-previous-btn {
  width: auto;
  padding: 0;
  border-radius: 0;
  margin: 0;
  background: transparent;
  border: none;
}
.top-action-box .form-wizard-previous-btn:hover {
  background: transparent;
  opacity: 0.5;
}
.step-pager ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.step-pager ul li {
  margin-right: 60px;
  position: relative;
  font-size: 16px;
  font-weight: normal;
  color: #333333;
}
.step-pager ul li:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background: #333;
  right: -45px;
  top: 8px;
}
.step-pager ul li:last-child {
  margin: 0;
}
.step-pager ul li:last-child:after {
  display: none;
}

.wallet-address-box {
  padding: 45px 0 0;
  width: 100%;
}

.user-choice-box {
  display: flex;
  align-items: center;
}
.wall-bx {
  /* width: 600px; */
  margin: 0 auto;
  display: table;
  max-width: 825px;
  vertical-align: middle;
}
.wall-bx h2 {
  font-size: 42px;
  font-weight: 800;
  color: #000;
  line-height: 52px;
  text-align: center;
  margin: 0 0 25px;
  font-family: "acumin-pro";
}
.wall-bx h2 span {
  color: #fe79ca;
}
.wall-bx p {
  font-size: 20px;

  color: #000;
  line-height: 30px;
  text-align: center;
  margin: 0 auto 35px;
  display: table;
  width: 85%;
  font-family: "acumin-pro";
}

.wallBxBoldClass {
  font-weight: 600;
}
.wall-actbx {
  width: 675px;
  margin: 0 auto;
  /* display: table; */
  display: flex;
}
.wall-actbx .btn-main {
  text-align: center;
  padding: 12px 20px 6px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: normal;
  color: #232740;
  width: 100%;
  display: block;
  letter-spacing: 1px;
  text-align: center;

  background-color: #3bc86b;

  text-decoration: none;
}
.wall-actbx h5 {
  margin: 35px 0;
  text-align: center;
  font-size: 28px;
  font-weight: normal;
  line-height: 28px;
  color: #000;
}
.innerStep3 h2 {
  font-size: 42px;
  margin: 0 0 30px;
  line-height: 52px;
}
.plugging-checklist {
  width: 700px;
  margin: 0 auto;
}
.plugging-checklist h3 {
  font-size: 18px;
  font-weight: normal;
  color: #060710;
  text-align: center;
  /* font-family: "PP Formula Condensed"; */
  font-family: "acumin-pro";
  margin: 0 0 30px;
}
.plugging-checklist ul {
  margin: 0 0 20px;
}
.plugging-checklist .list-item {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  padding: 15px 25px;

  border-radius: 20px;
  cursor: pointer;
}
.list-item-bg {
  background: #f4f3f6;
}
.active-list-item-bg {
  background-color: #77f9a3;
}
.plugging-checklist li input {
  display: none;
}
.checking-span {
  width: 50px;
  height: 50px;

  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checking-span-bg {
  background-color: #fe79ca;
}
.checking-span-active-bg {
  background-color: #fff;
}

.checking-span-img {
  display: none;
}
.checking-span-active-img {
  display: block;
}
.checkHeadline {
  padding-left: 25px;
}
.checkHeadline h4 {
  font-size: 20px;
  font-weight: bold;
  color: #060710;

  font-family: "acumin-pro";
  margin: 0;
}
.checkHeadline p {
  font-size: 16px;
  font-weight: normal;
  color: #060710;
  font-family: "acumin-pro";
  margin: 0;
  line-height: 26px;
  text-align: left;
  width: 100%;
}
.plugging-checklist li.selected {
  background: #31cd63;
}
.plugging-checklist li.selected .checking span {
  background: #fff;
}
.plugging-checklist li.selected .checking span img {
  display: block;
}
.plugging-checklist li.selected p,
.plugging-checklist li.selected h4 {
}
.identify-choice h2 {
  margin: 0 0 15px;
}
.identify-choice .plugging-checklist h3 {
  margin: 0 0 45px;
}
.final-step-wrp {
  padding: 0;
}
.final-step-wrp h2 {
  margin: 0 0 35px;
}
.final-step-wrp p:last-child {
  margin: 0 auto;
}

.your-code-wrp {
  padding: 70px 0;
  width: 100%;
  background-color: #ffdf36;
}
.your-code-wrp h2 {
  font-size: 42px;
  font-weight: 800;
  color: #000;
  text-align: center;
  margin: 0 0 20px;
  font-family: "PP Formula", Arial, Helvetica, sans-serif;
}
.codeBox {
  margin: 0 auto 18px;
  display: table;
}
.codeBox .form-control {
  padding: 10px 20px 0;
  background: #fff;
  border-radius: 0;
  border: 5px solid #000000;
  height: auto;
  box-shadow: none;
  width: 100%;
  text-align: center;
  color: #ff8282;
  font-style: italic;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 2px;
}
.codeBox .form-control:focus {
  box-shadow: none;
  border: 5px solid #000000;
}

.your-code-wrp .btn-share {
  font-size: 18px;
  font-weight: normal;
  color: #232740;
  padding: 12px 20px 6px;
  border-radius: 30px;
  background: linear-gradient(0deg, #77f9a3, #77f9a3);
  text-align: center;
  width: 300px;
  margin: 0 auto;
  display: table;
  border: none;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
}
.your-code-wrp .btn-share:hover {
  background: #232740;
  color: #fff;
}
/* ***** End Wizard ****** */

/* new pages css */
.inner-content-box {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 60px 0; */
}

.addressBox {
  max-width: 840px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.addressBox1 {
  display: flex;
  align-items: center;
  height: 60vh;
}

.addressBox p {
  font-size: 1.5rem;
  font-weight: 300;
  color: #000;
  line-height: 1.4;
  text-align: center;
  margin: 0 0 40px;
  font-family: "acumin-pro";
}
.btnBox {
  display: flex;
}
.btnBox .main-btn {
  font-size: 18px;
  font-weight: normal;
  color: #232740;
  padding: 12px 20px;
  border-radius: 30px;
  background: linear-gradient(0deg, #77f9a3, #77f9a3);
  text-align: center;
  width: 300px;
  margin: 0 auto;
  display: table;
  border: none;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  text-decoration: none;
}

.btnBox .main-btn:hover {
  background: #ff8282;
  color: #fff;
}
.infoBox {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.infoBox p {
  font-size: 19px;
  font-weight: 300;
  color: #000;
  line-height: 36px;
  font-family: "acumin-pro";

  margin: 0 0 20px;
}

.infoBox ul {
  font-size: 19px;
  font-weight: 300;
  color: #000;
  line-height: 36px;
  font-family: "acumin-pro";
  margin-left: 30px;
}

.mintBox {
  display: flex;
  /* align-items: center; */
}

.infoImg {
  max-width: 100%;
  /* margin: 0 0 0 auto; */
}
.z-50 {
  z-index: 50;
}
.z-100 {
  z-index: 100;
}
.infoBox .radial-btn {
  font-size: 18px;
  font-weight: normal;
  color: #232740;
  padding: 12px 20px;
  border-radius: 30px;
  background: radial-gradient(
    51.23% 1206.62% at 46.37% 42.2%,
    #febf10 0%,
    rgba(254, 91, 190, 0.82) 79.69%
  );

  width: 300px;
  margin: 0 auto;

  border: none;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  text-decoration: none;
}
.infoBox .radial-btn:focus {
  box-shadow: none;
}

.infoBox .radial-btn:hover {
  color: white;
}

.inviteCodeRadialBtn {
  font-size: 36px;
  font-weight: 800;
  color: black;
  padding: 18px 30px;
  border-radius: 50px;
  white-space: nowrap;
  /* font-family: "PP Formula Condensed"; */
  text-transform: uppercase;
  background: radial-gradient(
    51.23% 1206.62% at 46.37% 42.2%,
    #febf10 0%,
    rgba(254, 91, 190, 0.82) 79.69%
  );

  width: 300px;
  margin: 0 auto;

  border: none;
  transition: 0.4s linear;
  -webkit-transition: 0.4s linear;
  text-decoration: none;
  line-height: 60px;
}
.inviteCodeRadialBtn:hover {
  color: white;
}

/* new 563 */
.identify-checklist {
  width: 1000px;
  margin: 0 auto;
}
.identify-checklist h3 {
  font-size: 18px;
  font-weight: normal;
  color: #060710;
  text-align: center;
  /* font-family: "PP Formula Condensed"; */
  font-family: "acumin-pro";
  margin: 0 0 30px;
}
.identify-checklist ul {
  margin: 0 0 20px;
}
.identify-checklist .list-item {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
  padding: 15px 25px;

  border-radius: 20px;
  cursor: pointer;
}
.list-item-bg {
  background: #f4f3f6;
}
.active-list-item-bg {
  background-color: #77f9a3;
}
.identify-checklist li input {
  display: none;
}

.logo {
  height: 30px;
  max-width: 100%;
  object-fit: contain;
}

/* modal classes */

.model {
  background: rgba(223, 82, 169, 0.24);
  border: 2px solid #fe5bbe;
  border-radius: 1.3rem !important;
  padding: 18px 22px;
}
.modal .modal-dialog .modal-content {
  border-radius: 1.3rem;
  border: none;
}
.line {
  height: 150px;
  width: 4px;
  margin: auto 0;
}
.modal-dialog .modal-content .model .modal-body {
  padding: 0;
}

.modal-img {
  max-width: 100%;
  margin: 0 auto;
  height: 100px;
  object-fit: contain;
}
.modal-img1 {
  max-width: 60%;
  margin: 0 auto;
  height: 100px;
  object-fit: contain;
}

.modal-img2 {
  max-width: 70%;
  margin: 0 auto;
  height: 100px;
  object-fit: contain;
}

.modal-btn {
  background: transparent;
  color: white;
  font-size: 14px;
  line-height: normal;
  margin-top: 24px;
}

.mainlogo {
  height: 60px;
  object-fit: contain;
  margin: 10px auto;
}
.margin {
  margin-top: 24% !important;
}

.input-field {
  width: 70% !important;
  margin: 0 auto;
}
.welcome-section {
  height: 85vh;
}
.copy-text {
  text-align: center;
  font-size: 17px;
  margin-bottom: 0 !important;
}

.welcomePageSpan {
  color: #009e35;
  font-weight: bold;
}

.fontweight {
  font-weight: bold !important;
}
.CardElement {
  padding: 14px;
  border: 3px solid black;
  margin-top: 10px;
  width: 49%;
}

.mintBoxforPaymentCard {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userChoiceTextPadding {
  padding: 0 9vw;
}

.mint-container {
  max-width: 1470px;
  margin: 0 auto;
}

.mintBtnContainer {
  text-align: left;
}

