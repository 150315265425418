@media (min-width: 1201px) and (max-width: 1600px) {
  .infoImg {
    max-width: 50%;
    /* margin: 0 0 0 auto; */
  }
  .positionRelative {
    position: relative;
  }
  .positionFixed {
    position: fixed;
  }
  .signup-wrp {
    padding: 74px 0;
  }
  .signup-wrp h1 {
    font-size: 34px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 {
    font-size: 34px;
    line-height: 48px;
    margin: 0 0 25px;
  }
  .invite-code {
    padding: 30px 0;
  }
  .code-frm {
    width: 75%;
  }
  .rightbarfix {
    right: 30px;
    top: 85%;
  }
  .code-frm h3 {
    font-size: 32px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 25px;
  }
  .rightbarfix ul li a {
    font-size: 12px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 32px;
  }
  .code-frm button {
    font-size: 16px;
  }

  .inner-wizard-box h2 {
    font-size: 34px;
    line-height: 48px;
  }

  /* .inner-content-dt p {
    margin: 0 0 25px;
  } */
  .inner-content-dt h6 {
    margin: 0 0 45px;
  }
  /* .wallet-address-box{padding: 200px 0 0;} */
  .identify-choice .plugging-checklist h3 {
    margin: 0 0 15px;
  }
  .wall-bx h2 {
    font-size: 34px;
    line-height: 48px;
  }
  .wall-bx p {
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 22px;
    margin: 20px 0;
  }
  .checkHeadline h4 {
    font-size: 24px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .plugging-checklist li {
    padding: 15px;
  }
  .plugging-checklist {
    width: 500px;
  }
  .checking span img {
    width: 25px;
  }
  .step-wrapper {
    padding: 35px 0;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 15px;
  }
  .your-code-wrp h2 {
    font-size: 34px;
  }
  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .signup-wrp {
    padding: 40px 0;
  }
  .signup-wrp h1 {
    font-size: 34px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 {
    font-size: 34px;
    line-height: 48px;
    margin: 0 0 25px;
  }
  .code-frm {
    width: 70%;
  }
  .rightbarfix {
    right: 30px;
    top: 85%;
  }
  .code-frm h3 {
    font-size: 32px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 25px;
  }
  .rightbarfix ul li a {
    font-size: 12px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 32px;
  }
  .code-frm button {
    font-size: 16px;
  }

  .inner-wizard-box h2 {
    font-size: 34px;
    line-height: 48px;
  }
  /* .inner-content-dt{width: 80%;} */
  .inner-content-dt p {
    margin: 0 0 25px;
  }
  .inner-content-dt h6 {
    margin: 0 0 45px;
  }

  .wall-bx h2 {
    font-size: 34px;
    line-height: 48px;
  }
  .wall-bx p {
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 22px;
    margin: 20px 0;
  }
  .checkHeadline h4 {
    font-size: 24px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .plugging-checklist li {
    padding: 15px;
  }
  .plugging-checklist {
    width: 500px;
  }
  .checking span img {
    width: 25px;
  }
  .step-wrapper {
    padding: 45px 0;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 15px;
  }
  .your-code-wrp h2 {
    font-size: 34px;
  }

  .infoBox {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    width: 98%;
  }

  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .signup-wrp {
    padding: 40px 0;
  }
  .signup-wrp h1 {
    font-size: 34px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 {
    font-size: 34px;
    line-height: 48px;
    margin: 0 0 25px;
  }
  .code-frm {
    max-width: 80%;
    width: 80%;
  }
  .rightbarfix {
    position: relative;
    top: 0;
    transform: rotate(0);
    right: 0;
    transform-origin: center;
  }
  .code-frm h3 {
    font-size: 32px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 25px;
  }
  .rightbarfix ul li a {
    font-size: 14px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 32px;
  }
  .code-frm button {
    font-size: 16px;
  }

  .inner-wizard-box h2 {
    font-size: 34px;
    line-height: 48px;
  }

  .inner-content-dt p {
    margin: 0 0 25px;
  }
  .inner-content-dt h6 {
    margin: 0 0 45px;
  }

  .wall-bx h2 {
    font-size: 34px;
    line-height: 48px;
  }
  .wall-bx p {
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 22px;
    margin: 20px 0;
  }
  .checkHeadline h4 {
    font-size: 24px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .plugging-checklist li {
    padding: 15px;
  }
  .plugging-checklist {
    width: 500px;
  }
  .checking span img {
    width: 25px;
  }
  .step-wrapper {
    padding: 45px 0;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 15px;
  }
  .your-code-wrp h2 {
    font-size: 34px;
  }

  .infoBox {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    width: 98%;
  }

  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }
  .row-height {
    height: 80vh;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .infoImg {
    max-width: 50%;
    /* margin: 0 0 0 auto; */
  }
  .signup-wrp {
    padding: 40px 0;
  }
  .signup-wrp h1 {
    font-size: 34px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 {
    font-size: 34px;
    line-height: 48px;
    margin: 0 0 25px;
  }
  .code-frm {
    max-width: 80%;
    width: 80%;
  }
  .rightbarfix {
    position: relative;
    top: 0;
    transform: rotate(0);
    right: 0;
    transform-origin: center;
  }
  .code-frm h3 {
    font-size: 32px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 25px;
  }
  .rightbarfix ul li a {
    font-size: 14px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 32px;
  }
  .code-frm button {
    font-size: 16px;
  }

  .inner-wizard-box h2 {
    font-size: 34px;
    line-height: 48px;
  }
  /* .inner-content-dt{width: 80%;} */
  .inner-content-dt p {
    margin: 0 0 25px;
  }
  .inner-content-dt h6 {
    margin: 0 0 45px;
  }

  /* .wallet-address-box{padding: 45px 0 0;} */
  .wall-bx h2 {
    font-size: 34px;
    line-height: 48px;
  }
  .wall-bx p {
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 22px;
    margin: 20px 0;
  }
  .checkHeadline h4 {
    font-size: 24px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .plugging-checklist li {
    padding: 15px;
  }
  .plugging-checklist {
    width: 500px;
  }
  .checking span img {
    width: 25px;
  }
  .step-wrapper {
    padding: 45px 0;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 15px;
  }
  .your-code-wrp h2 {
    font-size: 34px;
  }
  ul {
    padding-left: 0 !important;
  }

  .infoBox {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    width: 98%;
  }

  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .infoImg {
    max-width: 50%;
    /* margin: 0 0 0 auto; */
  }
  .signup-wrp {
    padding: 40px 0;
  }
  .signup-wrp h1 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .signup-wrp h2 {
    font-size: 28px;
    line-height: 38px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 br {
    display: none;
  }
  .code-frm {
    max-width: 80%;
    width: 80%;
  }
  .rightbarfix {
    position: relative;
    top: 0;
    transform: rotate(0);
    right: 0;
    transform-origin: center;
  }
  .code-frm h3 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 25px;
  }
  .rightbarfix ul li a {
    font-size: 14px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 28px;
  }

  .inner-wizard-box h2 {
    font-size: 28px;
    line-height: 38px;
    /* text-align: center; */
  }
  /* .inner-content-dt{width: 100%;} */
  .inner-content-dt p {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 15px;
  }

  .addressBox p {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 15px;
  }

  .btnBox {
    flex-direction: column;
  }
  .inner-content-dt h6 {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 25px;
  }
  .form-wizard .form-wizard-next-btn,
  .form-wizard .form-wizard-previous-btn,
  .form-wizard .form-wizard-submit {
    text-align: center;
  }
  .step-wrapper {
    padding: 40px 0;
  }

  .top-action-box .row {
    display: flex;
  }
  .top-action-box .row .col-md-4 {
    width: 40%;
  }
  .top-action-box .row .col-md-8 {
    width: 60%;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 14px;
    margin-right: 30px;
  }
  .step-pager ul li:after {
    width: 15px;
    right: -22px;
  }

  /* .wallet-address-box{padding: 60px 0 0;} */
  .wall-bx h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .wall-bx h2 br {
    display: none;
  }
  .wall-bx p {
    width: 100%;
    font-size: 15px;
    line-height: 26px;
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 18px;
    margin: 25px 0;
  }
  .wall-bx {
    width: 100%;
  }
  .plugging-checklist {
    width: 100%;
  }
  .plugging-checklist li {
    padding: 15px;
    align-items: flex-start;
  }
  .checkHeadline {
    padding-left: 15px;
  }
  .checkHeadline h4 {
    font-size: 22px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .checking span img {
    width: 20px;
  }
  .wallet-address-box.innerStep3.identify-choice .plugging-checklist li {
    align-items: center;
  }
  .your-code-wrp h2 {
    font-size: 28px;
  }
  .codeBox .form-control {
    font-size: 32px;
  }
  .your-code-wrp .btn-share {
    font-size: 16px;
  }
  ul {
    padding-left: 0 !important;
  }

  .infoBox {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    width: 98%;
  }

  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }

  .mintBox {
    height: auto;
    display: flex;
    align-items: center;
  }
  .CardElement {
    padding: 14px;
    border: 3px solid black;
    margin-top: 10px;
    width: 100%;
  }
  .mintBoxforPaymentCard {
    height: auto !important;
    display: flex;
    align-items: center;
  }

  .mintBtnContainer {
    text-align: center;
  }
}

@media (min-width: 448px) and (max-width: 575px) {
  .infoImg {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .signup-wrp {
    padding: 40px 0;
  }
  .signup-wrp h1 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .signup-wrp h2 {
    font-size: 28px;
    line-height: 38px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 br {
    display: none;
  }
  .code-frm {
    max-width: 100%;
    width: 100%;
  }
  .rightbarfix {
    position: relative;
    top: 0;
    transform: rotate(0);
    right: 0;
    transform-origin: center;
  }
  .code-frm h3 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .code-frm button {
    font-size: 15px;
    text-align: center;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 15px;
  }
  .rightbarfix ul li a {
    font-size: 12px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 28px;
  }

  .inner-wizard-box h2 {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  .inner-content-dt {
    width: 100%;
  }
  .inner-content-dt p {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 15px;
  }
  .addressBox p {
    font-size: 1.1rem;
    line-height: 1.3;
    margin: 0 0 15px;
  }
  .btnBox {
    flex-direction: column;
  }
  .inner-content-dt h6 {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 25px;
  }
  .form-wizard .form-wizard-next-btn,
  .form-wizard .form-wizard-previous-btn,
  .form-wizard .form-wizard-submit {
    text-align: center;
  }
  .step-wrapper {
    padding: 40px 0;
  }

  .top-action-box .row {
    display: flex;
  }
  .top-action-box .row .col-md-4 {
    width: 40%;
  }
  .top-action-box .row .col-md-8 {
    width: 60%;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 14px;
    margin-right: 30px;
  }
  .step-pager ul li:after {
    width: 15px;
    right: -22px;
  }
  .wallet-address-box {
    padding: 60px 0 0;
  }
  .wall-bx h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .wall-bx h2 br {
    display: none;
  }
  .wall-bx p {
    width: 100%;
    font-size: 15px;
    line-height: 26px;
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 18px;
    margin: 25px 0;
  }
  .wall-bx {
    width: 100%;
  }
  .plugging-checklist {
    width: 100%;
  }
  .plugging-checklist li {
    padding: 15px;
    align-items: flex-start;
  }
  .checkHeadline {
    padding-left: 15px;
  }
  .checkHeadline h4 {
    font-size: 22px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .checking span img {
    width: 20px;
  }
  .wallet-address-box.innerStep3.identify-choice .plugging-checklist li {
    align-items: center;
  }
  .your-code-wrp h2 {
    font-size: 28px;
  }
  .codeBox .form-control {
    font-size: 32px;
  }
  .your-code-wrp .btn-share {
    font-size: 16px;
  }
  ul {
    padding-left: 0 !important;
  }

  .infoBox {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    width: 98%;
  }

  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }
  .CardElement {
    padding: 14px;
    border: 3px solid black;
    margin-top: 10px;
    width: 100%;
  }
  .mintBoxforPaymentCard {
    height: auto !important;
    display: flex;
    align-items: center;
  }

  .mintBtnContainer {
    text-align: center;
  }
  .inviteCodeRadialBtn {
    font-size: 24px;
  }
}

/* Mobile */
@media (min-width: 375px) and (max-width: 447px) {
  .inviteCodeRadialBtn {
    font-size: 24px;
  }
  .mainlogo {
    height: 30px;
  }
  .infoImg {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .signup-wrp {
    padding: 40px 0;
  }
  .signup-wrp h1 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .signup-wrp h2 {
    font-size: 28px;
    line-height: 38px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 br {
    display: none;
  }
  .code-frm {
    max-width: 100%;
    width: 100%;
  }
  .rightbarfix {
    position: relative;
    top: 0;
    transform: rotate(0);
    right: 0;
    transform-origin: center;
  }
  .code-frm h3 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .code-frm button {
    font-size: 15px;
    text-align: center;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 15px;
  }
  .rightbarfix ul li a {
    font-size: 12px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 28px;
  }

  .inner-wizard-box h2 {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  .inner-content-dt {
    width: 100%;
  }
  .inner-content-dt p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 0 15px;
  }
  .addressBox p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 0 15px;
  }
  .btnBox {
    flex-direction: column;
  }
  .inner-content-dt h6 {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 25px;
  }
  .form-wizard .form-wizard-next-btn,
  .form-wizard .form-wizard-previous-btn,
  .form-wizard .form-wizard-submit {
    text-align: center;
  }
  .step-wrapper {
    padding: 40px 0;
  }

  .top-action-box .row {
    display: flex;
  }
  .top-action-box .row .col-md-4 {
    width: 40%;
  }
  .top-action-box .row .col-md-8 {
    width: 60%;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 14px;
    margin-right: 30px;
  }
  .step-pager ul li:after {
    width: 15px;
    right: -22px;
  }
  .wallet-address-box {
    padding: 60px 0 0;
  }
  .wall-bx h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .wall-bx h2 br {
    display: none;
  }
  .wall-bx p {
    width: 100%;
    font-size: 15px;
    line-height: 26px;
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 18px;
    margin: 25px 0;
  }
  .wall-bx {
    width: 100%;
  }
  .plugging-checklist {
    width: 100%;
  }
  .plugging-checklist li {
    padding: 15px;
    align-items: flex-start;
  }
  .checkHeadline {
    padding-left: 15px;
  }
  .checkHeadline h4 {
    font-size: 22px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .checking span img {
    width: 20px;
  }
  .wallet-address-box.innerStep3.identify-choice .plugging-checklist li {
    align-items: center;
  }
  .your-code-wrp h2 {
    font-size: 28px;
  }
  .codeBox .form-control {
    font-size: 32px;
  }
  .your-code-wrp .btn-share {
    font-size: 16px;
  }
  ul {
    padding-left: 0 !important;
  }

  .infoBox {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    width: 98%;
  }

  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }
  .CardElement {
    padding: 14px;
    border: 3px solid black;
    margin-top: 10px;
    width: 100%;
  }
  .mintBoxforPaymentCard {
    height: auto !important;
    display: flex;
    align-items: center;
  }

  .mintBtnContainer {
    text-align: center;
  }
}

/* Small Mobile */
@media (max-width: 374px) {
  .mainlogo {
    height: 20px;
  }
  .infoImg {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .signup-wrp {
    padding: 40px 0;
  }
  .signup-wrp h1 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .signup-wrp h2 {
    font-size: 28px;
    line-height: 38px;
    margin: 0 0 25px;
  }
  .signup-wrp h2 br {
    display: none;
  }
  .code-frm {
    max-width: 100%;
    width: 100%;
  }
  .rightbarfix {
    position: relative;
    top: 0;
    transform: rotate(0);
    right: 0;
    transform-origin: center;
  }
  .code-frm h3 {
    font-size: 28px;
    margin: 0 0 15px;
  }
  .formBox .form-control {
    font-size: 15px;
    padding: 10px 15px;
  }
  .code-frm button {
    font-size: 15px;
    text-align: center;
  }
  .rightbarfix {
    padding: 30px 0 0;
  }
  .rightbarfix ul {
    justify-content: center;
  }
  .rightbarfix ul li {
    margin-right: 10px;
  }
  .rightbarfix ul li a {
    font-size: 10px;
    padding: 0;
  }
  .rightbarfix ul li:after {
    display: none;
  }
  .invite-code h2 {
    font-size: 28px;
  }

  .inner-wizard-box h2 {
    font-size: 28px;
    line-height: 38px;
    text-align: center;
  }
  .inner-content-dt {
    width: 100%;
  }
  .inner-content-dt p {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 15px;
  }
  .addressBox p {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 15px;
  }
  .btnBox {
    flex-direction: column;
  }
  .inner-content-dt h6 {
    font-size: 15px;
    line-height: 26px;
    margin: 0 0 25px;
  }
  .form-wizard .form-wizard-next-btn,
  .form-wizard .form-wizard-previous-btn,
  .form-wizard .form-wizard-submit {
    text-align: center;
  }
  .step-wrapper {
    padding: 40px 0;
  }

  .top-action-box .row {
    display: flex;
  }
  .top-action-box .row .col-md-4 {
    width: 40%;
  }
  .top-action-box .row .col-md-8 {
    width: 60%;
  }
  .top-action-box .form-wizard-previous-btn img {
    width: 60px;
  }
  .step-pager ul li {
    font-size: 14px;
    margin-right: 30px;
  }
  .step-pager ul li:after {
    width: 15px;
    right: -22px;
  }
  .wallet-address-box {
    padding: 60px 0 0;
  }
  .wall-bx h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .wall-bx h2 br {
    display: none;
  }
  .wall-bx p {
    width: 100%;
    font-size: 15px;
    line-height: 26px;
    width: 100%;
  }
  .wall-actbx h5 {
    font-size: 18px;
    margin: 25px 0;
  }
  .wall-bx {
    width: 100%;
  }
  .plugging-checklist {
    width: 100%;
  }
  .plugging-checklist li {
    padding: 15px;
    align-items: flex-start;
  }
  .checkHeadline {
    padding-left: 15px;
  }
  .checkHeadline h4 {
    font-size: 22px;
  }
  .checking span {
    width: 45px;
    height: 45px;
  }
  .checking span img {
    width: 20px;
  }
  .wallet-address-box.innerStep3.identify-choice .plugging-checklist li {
    align-items: center;
  }
  .your-code-wrp h2 {
    font-size: 28px;
  }
  .codeBox .form-control {
    font-size: 32px;
  }
  .your-code-wrp .btn-share {
    font-size: 16px;
  }
  ul {
    padding-left: 0 !important;
  }

  .infoBox {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    width: 98%;
  }

  .identify-checklist {
    width: revert;
    margin: 0 auto;
  }
  .identify-checklist ul {
    margin: 0 0 20px;
    padding-left: 0 !important;
  }
  .CardElement {
    padding: 14px;
    border: 3px solid black;
    margin-top: 10px;
    width: 100%;
  }
  .mintBoxforPaymentCard {
    height: auto !important;
    display: flex;
    align-items: center;
  }

  .mintBtnContainer {
    text-align: center;
  }
}
